import { Layout, MaxWidth, SubHeader } from 'components/global'
import SingleBlog from 'components/shared/singleblog'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'

const NewsBody = styled.div`
  width: 100%;
  background-color: var(--offWhite);
  section {
    padding: 1.5rem 0;
    h1 {
      color: var(--darkGrey);
      text-align: center;
    }
    .wrapper {
      margin: 2rem 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.25rem;
      @media ${media.md} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media ${media.lg} {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
`
const News = ({ data }) => {
  const news = data.Blogs.nodes
  return (
    <Layout $noAnimation>
      <SubHeader title="All News" />
      <NewsBody>
        <MaxWidth>
          <section>
            <div className="wrapper">
              {news.map((data, i) => (
                <SingleBlog data={data} key={i} />
              ))}
            </div>
          </section>
        </MaxWidth>
      </NewsBody>
    </Layout>
  )
}

export default News

export const query = graphql`
  query NewsQuery {
    Blogs: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        description
        publishedAt(formatString: "MMM DD YYYY")
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
